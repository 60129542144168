<template>
  <div class="accordion-group">
    <slot>
      <AccordionItem
        :active="activeIndex === index"
        v-for="(item, index) in items"
        :key="index"
        @onClick="setActive(index)"
      >
        <template #title>{{ item.title }}</template>
        <template #body>{{ item.body }}</template>
      </AccordionItem>
    </slot>
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AccordionItem,
  },
  data: () => ({
    activeIndex: null,
  }),
  methods: {
    setActive(index) {
      if (this.activeIndex === index) this.activeIndex = null;
      else this.activeIndex = index;
    },
  },
};
</script>

<style></style>
