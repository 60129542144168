<template>
  <div class="accordion-item" :class="{ active: active }">
    <div class="accordion-item-head" @click="$emit('onClick')">
      <h5><slot name="title"></slot></h5>
      <i class="fa-solid fa-angle-down"></i>
    </div>
    <div class="accordion-item-body">
      <div>
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
