<template>
  <div id="spanishCourses">
    <section
      class="page-header"
      style="background-image: url('/imgs/courses-01.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("title") }}</h1>
      </div>
    </section>

    <section class="py-6">
      <div
        class="container mb-6"
        v-for="(s, sindex) of $lang('sections')"
        :key="s.title"
      >
        <h4 class="text-primary mb-4">{{ s.title }}</h4>

        <AccordionGroup>
          <AccordionItem
            v-for="(c, cindex) of s.children"
            :key="c.title"
            @onClick="setActive(`${sindex}_${cindex}`)"
            :active="activeIndex === `${sindex}_${cindex}`"
          >
            <template #title>{{ c.title }}</template>
            <template #body>
              <div class="row lh-5 text-medium">
                <div
                  class="col-md-7"
                  :class="c.details ? 'col-md-7' : 'col-md-12'"
                >
                  <div v-html="c.description" />
                  <div
                    class="img-container contain"
                    v-if="c.logo"
                    :style="`background-image: url('${c.logo}')`"
                  />
                </div>
                <div class="col-md-5" v-html="c.details" v-if="c.details" />
                <div class="col-12" v-html="c.price_details" />
              </div>
              <button
                class="btn btn-primary px-4 mx-auto mt-5"
                @click="$router.push('/contact-us')"
              >
                {{ c.button }}
              </button>

              <small
                class="text-right mt-4 d-block text-medium"
                v-if="c.small_details"
              >
                {{ c.small_details }}
              </small>
            </template>
          </AccordionItem>
        </AccordionGroup>
      </div>
      <button
        class="btn btn-tertiary text-white px-5 mx-auto mt-5"
        @click="$router.push('/contact-us')"
      >
        {{ $lang("button") }}
      </button>
    </section>
  </div>
</template>

<script>
import AccordionGroup from "@/components/AccordionGroup.vue";
import AccordionItem from "@/components/AccordionItem.vue";

export default {
  components: {
    AccordionGroup,
    AccordionItem,
  },
  data: () => ({
    activeIndex: null,
  }),
  methods: {
    setActive(index) {
      if (this.activeIndex === index) this.activeIndex = null;
      else this.activeIndex = index;
    },
  },
};
</script>
